.TableDE, th, td, tr {
  border: 1px solid black;
  border-collapse: collapse;
  margin: 10px;
}

.TableDE, th {
  border: 1px solid black;
  border-collapse: collapse;
  margin: 10px;
  /* padding-right: 50px;
  padding-left: 50px; */
  padding-top: 5px;
  padding-bottom: 5px;
}

.TableDE, td {
  border: 1px solid black;
  border-collapse: collapse;
  margin: 10px;
  /* padding-right: 50px;
  padding-left: 50px; */
  padding-top: 5px;
  padding-bottom: 5px;
}

.p_font_New2{
  font-size: 1rem;
}