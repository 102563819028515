body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a{
  text-decoration: none;
}

*{
  outline: none !important;
}

.MuiMenuItem-root{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif !important;
}

.color-blueRegular{
  color: #317dbd;
  font-weight: 700;
}

.color-blueBold{
  color: #317dbd;
  font-weight: 700;
}

.btn-primary{
  color: #fff;
  background: #4BC7E9;
  background: linear-gradient(to bottom, #4BC7E9, #317DBD);
  padding: .375rem .75rem;
  text-decoration: none;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 75%;
}

.btn-grey{
  color: #000;
  background: #dddcdc;
  padding: .375rem .75rem;
  text-decoration: none;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 75%;
}

.MuiInput-underline:before {
  border-bottom: none !important;
}
.MuiInput-underline:after {
  border-bottom: none !important;
}

.MuiInputBase-root {
  font-weight: 500 !important;
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
